var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"collect"},[_c('div',{staticClass:"head f-c-b"},[_c('div',{staticClass:"title"},[_vm._v("足迹")]),(_vm.list.length != 0)?_c('div',{staticClass:"delBtn",on:{"click":_vm.removeAllFootmarks}},[_vm._v("删除")]):_vm._e()]),(_vm.list && _vm.list.length)?_c('div',{staticClass:"footprintList f-w"},[(_vm.list && _vm.list.length)?[_vm._l((_vm.list),function(item,index){return _c('router-link',{key:index,staticClass:"footprintItem f-cl",attrs:{"to":{
                name: 'ProductDetail',
                query: {
                    key: _vm.$UrlEncode.encode(JSON.stringify({
                        id: item.productId
                    }))
                }
            }}},[_c('el-image',{staticClass:"img",attrs:{"src":item.img1Url}}),_c('div',{staticClass:"favorites-info f-cl-b"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.productName))]),(item.productNumber)?_c('span',{staticClass:"num"},[_vm._v("商品编号："+_vm._s(item.productNumber))]):_vm._e(),_c('div',{staticClass:"f-c-b infoBox"},[_c('span',{staticClass:"price"},[_vm._v(_vm._s(item.unitPrice ? (item.valuationCurrency + item.unitPrice) : '暂无报价'))])])]),_c('div',{staticClass:"cornermark"},[_vm._v(_vm._s(item.productTypeStr))])],1)}),_c('Pagination',{attrs:{"margin":'0px',"total":_vm.total,"pageNum":_vm.currentPage,"limit":_vm.size,"layout":_vm.layout},on:{"update:pageNum":function($event){_vm.currentPage=$event},"update:page-num":function($event){_vm.currentPage=$event},"update:limit":function($event){_vm.size=$event},"pagination":_vm.fetchData}})]:_vm._e()],2):_c('el-empty',{attrs:{"description":"暂无内容"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }