<template>
    <div class="collect">
        <div class="head f-c-b">
            <div class="title">足迹</div>
            <div v-if="list.length != 0" class="delBtn" @click="removeAllFootmarks">删除</div>
        </div>
        <div class="footprintList f-w" v-if="list && list.length">
            <template v-if="list && list.length">
                <router-link :to="{
                    name: 'ProductDetail',
                    query: {
                        key: $UrlEncode.encode(JSON.stringify({
                            id: item.productId
                        }))
                    }
                }" class="footprintItem f-cl" v-for="(item, index) of list" :key="index">
                    <!-- <el-image class="img" :src="item.img1Url"></el-image>
                    <div class="f-cl-b favorites-info ">
                        <span class="name">{{ item.productName }}</span>
                        <span class="num">商品编号：{{ item.productId }}</span>
                        <span class="price">{{ item.unitPrice ? (item.valuationCurrency + item.unitPrice) : '暂无报价'
                        }}</span>
                        <div class="cornermark">{{ item.productTypeStr }}</div>
                    </div> -->
                    <el-image class="img" :src="item.img1Url"></el-image>
                    <div class="favorites-info f-cl-b">
                        <span class="name">{{ item.productName }}</span>
                        <span v-if="item.productNumber" class="num">商品编号：{{ item.productNumber }}</span>
                        <div class="f-c-b infoBox">
                            <span class="price">{{ item.unitPrice ? (item.valuationCurrency + item.unitPrice) : '暂无报价'
                            }}</span>
                            <!-- <span class="status">在售</span> -->
                        </div>

                    </div>
                    <div class="cornermark">{{ item.productTypeStr }}</div>
                </router-link>
                <Pagination :margin="'0px'" :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
                    @pagination="fetchData" />
            </template>
        </div>
        <el-empty v-else description="暂无内容"></el-empty>
    </div>
</template>
<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { UnitAccount } from "@/utils/attributeValue1Options";
export default {
    name: '',
    mixins: [PaginationRetention],
    inject: ['reload'],
    data() {
        return {
            list: [],
        }
    },
    created() { },
    methods: {
        getDate() {
            this.productFootmarksList(this.paging, this.pagingSize)
        },
        // 足迹列表
        productFootmarksList(page = this.page, size = this.size) {
            let that = this;
            that.$http.productFootmarksList({
                lastModifyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    that.list = res.data.records;
                    that.list.forEach(item => {
                        item.productTypeStr = UnitAccount.getproductType(item.productType)
                    })
                    that.total = res.data.total;
                    that.currentPage = res.data.current;
                }
            })
        },
        // 移除全部浏览足迹接口
        removeAllFootmarks() {
            let that = this;
            that.$confirm('是否清除全部浏览足迹？', '提示').then(() => {
                that.$http.removeAllFootmarks({
                    lastModifyUserId: parseInt(that.$store.state.userId),
                }).then(res => {
                    if (res.code == 200) {
                        that.list = [];
                        that.total = 0;
                        that.currentPage = 0;
                        that.$common.message(res.msg, 'success', 1200)
                    }
                })
            }).catch(() => { })
        }
    },
    // beforeDestroy() {
    //     this.$removeStorage('fetchDataSize')
    //     this.$removeStorage('fetchDataPage')
    // }
}
</script>
<style lang="less" scoped>
.collect {
    .head {
        height: 40px;
        padding-bottom: 24px;
        border-bottom: 1px solid #F2F2F2;

        .title {
            // font-size: 24px;
            // font-weight: 500;
            // color: #333333;
            // height: 40px;
            // line-height: 40px;
            font-size: 24px;
            font-family: HarmonyOS Sans SC;
            color: #333333;
            line-height: 40px;
            // padding-bottom: 24px;
            font-weight: bold;
        }

        .delBtn {
            width: 80px;
            height: 40px;
            border-radius: 5px;
            background: linear-gradient(101deg, #006EF2 0%, #33A6F9 100%);
            text-align: center;
            line-height: 40px;
            color: #FFFFFF;
            cursor: pointer;
        }
    }

    .footprintList {
        margin-top: 20px;

        .footprintItem {
            // width: 380px;
            // height: 170px;
            // border-radius: 20px;
            // margin-bottom: 30px;
            // margin-right: 15px;
            // position: relative;
            border-radius: 20px;
            // height: 380px;
            width: 32%;
            background: #F6F8FB;
            margin-bottom: 20px;
            margin-right: 10px;
            cursor: pointer;
            position: relative;

            .img {
                // border-radius: 20px 0 0 20px;
                // height: 170px;
                // width: 190px;
                border-radius: 20px 20px 0 0;
                height: 220px;
            }

            // .favorites-info {
            //     border-radius: 0 20px 20px 0;
            //     background: #F6F8FB;
            //     height: 170px;
            //     width: 190px;

            //     .cornermark {
            //         position: absolute;
            //         right: 10px;
            //         top: 5px;
            //         border-radius: 5px;
            //         background: #ffffff;
            //         text-align: center;
            //         height: 14px;
            //         line-height: 14px;
            //         font-size: 12px;
            //         color: #699EF2;
            //         padding: 3px;
            //     }

            //     .name {
            //         margin: 30px 0 0 30px;
            //         font-size: 20px;
            //         font-weight: 400;
            //         word-break: break-all;
            //     }

            //     .num {
            //         margin-left: 30px;
            //         font-size: 16px;
            //         font-weight: 400;
            //         word-break: break-all;
            //     }

            //     .price {
            //         color: red;
            //         margin-left: 30px;
            //         margin-bottom: 30px;
            //         font-weight: 400;
            //         font-size: 18px;
            //     }
            // }
            .cornermark {
                position: absolute;
                right: 10px;
                top: 10px;
                border-radius: 5px;
                background: #ffffff;
                text-align: center;
                height: 14px;
                line-height: 14px;
                font-size: 12px;
                color: #699EF2;
                padding: 3px;
            }

            .favorites-info {
                padding: 15px;
                height: 80px;

                .name {
                    font-size: 20px;
                    font-weight: 400;
                    color: #000000;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .num {
                    font-size: 16px;
                    font-weight: 400;
                    color: #000000;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .infoBox {
                    // margin-top: 35px;

                    .price {
                        color: red;
                    }
                }
            }
        }
    }
}
</style>